import { useEffect, useRef } from 'react'
import opentype from 'opentype.js'

import './App.css'

let letter = 'f'
let draggingFrom = null
let offset = { x: 0, y: 0 }

let numberOfLetters = 5

const draw = (canvas, font, isIn = false) => {
  const width = canvas.offsetWidth
  const height = canvas.offsetHeight
  canvas.setAttribute('width', width)
  canvas.setAttribute('height', height)
  const center = { x: width / 2, y: height / 2 }
  const ctx = canvas.getContext('2d')

  ctx.fillStyle = '#2adfc1'
  ctx.fillRect(0, 0, width, height)
  const path = font.getPath(letter, offset.x, offset.y, 500)
  canvas.style.cursor = isIn ? 'move' : 'auto'
  ctx.translate(center.x, center.y)
  for (let i = 0; i < numberOfLetters; i++) {
    ctx.rotate(2 * Math.PI / numberOfLetters)
    path.fill = (isIn && i === numberOfLetters - 1) ? '#116660' : '#000000'
    path.draw(ctx)
  }
}

const initialLoad = async (canvas) => {
  const res = await fetch('https://fonts.cdnfonts.com/s/90500/LordSpiritRegular-L3DwG.woff')
  const font = opentype.parse(await res.arrayBuffer(), null)
  draw(canvas, font)
  window.addEventListener('resize', () => requestAnimationFrame(() => draw(canvas)))
  const ctx = canvas.getContext('2d')
  const { left, top } = canvas.getBoundingClientRect()
  canvas.addEventListener('mousedown', (evt) => {
    const x = evt.x - left
    const y = evt.y - top
    if (ctx.isPointInPath(x, y)) {
      draggingFrom = { x, y }
    }
  })
  canvas.addEventListener('mouseup', () => {
    draggingFrom = null
  })
  canvas.addEventListener('mousemove', (evt) => {
    const x = evt.x - left
    const y = evt.y - top
    draw(canvas, font, ctx.isPointInPath(x, y))
    if (draggingFrom) {
      offset.x += x - draggingFrom.x
      offset.y += y - draggingFrom.y
      draggingFrom.x = x
      draggingFrom.y = y
    }
  })
  window.addEventListener('keydown', ({ key }) => {
    // TODO: Add more fonts and cycle them on left / right
    if (key === 'ArrowUp') {
      numberOfLetters++
    } else if (key === 'ArrowDown') {
      numberOfLetters = Math.max(3, numberOfLetters - 1)
    } else if (key.length === 1 && key !== ' ') {
      letter = key
    }
    draw(canvas, font)
  })
}

const App = () => {
  const canvasRef = useRef(null)

  useEffect(() => {
    if (canvasRef.current) {
      initialLoad(canvasRef.current)
    }
  }, [canvasRef])

  return (
    <div className="page">
      <canvas ref={canvasRef} className="canvas"/>
    </div>
  )
}

export default App
